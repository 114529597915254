@import './style/topbar.css';
@import './style/authPage.css';
@import './style/creatianPage.css';
@import './style/lesson-mgmnt.css';
@import './style/lesson.css';
@import './style/loading.css';
@import './style/archivePage.css';
@import './style/mainPage.css';
@import './style/alarm.css';
@import './style/studentPage.css';
@import './style/profile.css';
@import './style/review.css';
@import './style/analytics.css';
@import './style/parent.css';
@import './style/hourInfoPage.css';
@import './style/switch.css';
@import './style/schedule.css';
@import './style/teachers-payroll.css';

:root {
  --blue: #00A7E2;
  --dark-blue: #0260AC;
  --green: #5DBC26;
  --green-gradient: linear-gradient(111.26deg, #C1D4B6 -7.84%, #5DBC26 64.01%);
  --red-gradient: linear-gradient(116.26deg, #F2164B 33.48%, #DA7E70 96.9%);
  --blue-gradient: linear-gradient(228.63deg, #0093D3 -19.63%, #0260AC 5.86%, #00A7E2 91.58%);
}

html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', Arial, sans-serif;
  color: rgb(29, 29, 29);
  background-image: url('img/bg.png');
  background-repeat:repeat-y;
  background-size: 100vw auto;
  padding-bottom: 40px;
}

li, ul,h3,h4,h5 {
  padding: 0;
  margin: 0;
}

li, ul{
  list-style: none;
}

.container {
  width: 1100px;
  margin: 0 auto;
}

.container--responsive {
  width: fit-content;
  min-width: 1100px;
}

p {
  margin: 0;
  padding: 0;
}

.d-flex {
  display: flex;
}

.review-filter__wrap input{
  display: block;
  padding: 10px 5px;
  border: 2px solid #bbb; 
  border-radius: 10px;
  min-width: 168px;
}

.yc_button {
  display: flex;
  border: none;
  border-radius: 30px;
  background-color: #ffba0f;
  color: #fff;
  padding: 10px 30px;
  font-weight: 800;
  font-size: 16px;
  cursor: pointer;
}

.yc_button:hover {
  opacity: .8;
}

.yc_button:active {
  color: #000;
  opacity: .4;
}

.onetime__list {
  display: flex;
  flex-wrap: wrap;
}

.onetime__wrap {
  display: flex;
  margin-top: 30px;
  margin-bottom: 50px;
}

.search-result__item {
  border: 1px solid #bbb;
  border-radius: 5px;
  margin-bottom: 1px;
  padding: 3px 7px;
  cursor: pointer;
  background-color: #fff;
}

.search-result__item:hover {
  background-color: rgb(114, 169, 232);
}

.search-result__item:active {
  box-shadow: #bbb 0px 0px 5px;
}

.d_none {
  opacity: 0;
  pointer-events: none;
}

.creation-page__wrap {
  display: flex;
  justify-content: space-between;
}

.user-search__input {
  width: 300px;
  height: 35px;
  border: 2px solid #bbb;
  border-radius: 10px;
  padding: 3px 10px;
} 

.lesson-item__date {
  font-size: 16px;
  text-align: center;
}

.lessons-item__header .lesson-item__date,
.lesson-item__date .lesson-item__date{
  width: 47px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-family: 'Montserrat', 'Arial',sans-serif;
}

.lesson-item__date .lesson-item__date {
  width: 70px;
}

.toast-wrap {
  color: #fff;
  position: fixed;
  top: 100px;
  right: 12%;
  max-width: 250px;
  height: 20px;
  padding: 10px 20px;
  background-color: rgb(180, 178, 178);
}


.toast-wrap--error {
  background-color: rgb(233, 99, 99);
}

.toast-wrap--success {
  background-color: rgb(33, 183, 38);
}

.not-your {
  background-color: rgb(219, 219, 219);
  border-radius: 10px;
} 

.row {
  display: flex;
}

.filial_select {
  width: initial;
  margin-right: 10px;
}

.changers-wrap {
  margin-bottom: 25px;
  border: 1px solid rgb(0, 147, 211);
  width: fit-content;

  padding: 10px 15px;
  background-color: rgba(255, 255, 255, 0.4);

  border-radius: 10px;
}

.changers-wrap h3 {
  padding: 0;
  margin: 0;
}

.grop__container--with-review {
  position: relative;
}

.need_reviews { 
  background: linear-gradient(116.26deg, #F2164B 33.48%, #DA7E70 96.9%);
}

.student-card__archive-group {
  background-color: #bbb;
  border-radius: 5px;
  margin-bottom: 5px;
}



@media screen and (max-width: 420px) {
  .container {
    box-sizing: border-box;
    width: 320px;
  }
}