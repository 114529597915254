.group__name {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 15px;
    color: #0260AC;
    cursor: pointer;
    width: 265px;
}

.group__name:hover {
    opacity: .6;
}

.group-wrap {
    margin-bottom: 20px;
    border: 2px solid #0093D3;
    padding: 10px 20px;
    width: fit-content;
    border-radius: 20px; 
    background-color: rgba(255, 255, 255, 0.4);
    position: relative;
}

.lessons-item__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    top: -75px;
    margin-left: -2px;
    position: absolute;
}

.lesson-attandance__box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 50px;
    box-sizing: border-box;
    padding-left: 10px;
    border: 1px solid #bbb;
    border-radius: 20px;
    color: #0264AF;
    font-size: 24px;
    /* font-weight: 600; */
    margin-top: 4px;
    margin-bottom: 6px;
}

.attandance__box--yes,
.attandance__box--no  {
    padding-left: 17px;
    -webkit-appearance: none;
    border-color: transparent;
    color: #fff;
}

.lesson-attandance__box option {
    display: block;
    margin-top: -25px;
}

.lesson-creation__date-input input {
    width: 80px;
    height: 25px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    font-family: 'Montserrat', 'Arial',sans-serif;
}

.lesson-creation__date-input input::-webkit-inner-spin-button,
.lesson-creation__date-input input::-webkit-calendar-picker-indicator{
    display: none;
    -webkit-appearance: none;
}

.lesson-creation__date-input input:hover {
    opacity: .6;
}

.lesson-creation__date-input input:active {
    opacity: .4;
}

.attandance__box--yes {

    background: linear-gradient(111.26deg, #C1D4B6 -7.84%, #5DBC26 64.01%);
}

.attandance__box--no {
    background: linear-gradient(116.26deg, #F2164B 33.48%, #DA7E70 96.9%);
}



.student-list li {
    font-size: 16px;
    margin-bottom: 21px;

    min-height: 36px;
    box-sizing: border-box;
}


.student-list {
    margin-right: 30px;
    min-width: 250px;
}

.student-list__name {
    text-decoration: none;
    cursor: pointer;
    color: #0093D3;
}

.student-list__name:hover {
    opacity: .6;
}

.lesson-item__remove-btn {
    background: linear-gradient(116.26deg, #F2164B 33.48%, #DA7E70 96.9%);
    color: #fff;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 5px;
    opacity: .6;
  }

  .lesson-item__remove-btn:hover {
    opacity: 1;
  }
  
  .lesson-item__remove-btn:active {
    opacity: .7;
  }
  
  .lessons-page__wrap {
    padding-bottom: 100px;
  }

  .lesson-item__date {
      font-weight: bold;
      color: #0260AC;
  }

  .lesson__item {
    position: relative;
    margin-right: 10px;
    text-align: center;
  }
  
  .lesson__hours {
    width: 100%;
    border: none;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    box-sizing: border-box;
    text-align: center;
    font-weight: 600;
    background-color: transparent;
  }

  .lesson-item__hours-wrap {
      width: 50px;
  }

  .lessons-item__archive-btn {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-family: "Roboto";
      color: #16A7E1;

      border: 1px solid #bbb;
      border-radius: 5px;
      padding: 3px 7px;
      margin-right: 10px;
  }

.lessons-item__archive-btn:hover {
    background-color: #16A7E1;
    color: #fff;
}

.add-to-group__wrap {
    position: relative;
}

.add-student__btn {
    background: transparent;
    border: none;
    border: 1px solid #bbb;
    display: block;
    padding: 5px 15px;
    border-radius: 10px;
    cursor: pointer;

}

.add-student__btn:hover {
    background-color: #16A7E1;
    color: #fff;
}

.student-list-add {
    position: absolute;
    z-index: 5;
}

.lesson-creation__wrap button:disabled{
    background: #bbb;
}

.searching-student__wrap {
    max-height: 200px;
    overflow-y: scroll;
}

.student-list__item {
    padding: 3px 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.student-list__del-btn {
    background: #fff;
    border: none;
    color:rgba(233, 69, 69, 0.574);
    cursor: pointer;
    margin-right: 7px;
    font-size: 16px;
    background-color: transparent;
}

.student-list__del-btn:hover {
    color:rgba(233, 69, 69, 1);
}

.lessons__manager-check {
    display: block;
    border: 1px solid #bbb;
    width: 15px;
    height: 15px;
    text-align: center;
}

.lessons__manager-check-wrap {
    margin-left: 12px;
}

.progress-bar-indicator {
    height: 100%;
    border-radius: 25px;
        /* this will do the magic */
    -webkit-mask:linear-gradient(#fff 0 0);
            mask:linear-gradient(#fff 0 0);
}

.progress-bar-indicator::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-image: linear-gradient(to right,red ,yellow, green); /* your gradient here */
}

.course-progress__wrap {
    position: absolute;
    width: 75px;
    height: 20px;
    border: 2px solid #bbb;
    border-radius: 25px;
    overflow: hidden;
}
.actual-courses__list {
    display: flex;

}

.progress__amount {
    text-align: center;
    width: 80px;
    position: absolute;
    top: 3px;
    left: 50%;
    margin-left: -40px;
}

.actual-student-course__wrap {
    margin-right: 5px;
    padding-left: 5px;
    max-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: -15px;
    min-height: 51px;
}

.actual-student-course__select {
    font-family: 'Roboto', "Arial", sans-serif;
    padding: 5px;
    font-size: 14px;
    width: 140px;
    
    border-radius: 15px;
    text-align: center;   
}

.student-progress__name-changer {
    width: 95px;
}

.one-time__hours-input {
    display: block;
    width: 50px;
}

.onetime-list__item {
    word-wrap: break-word;
}

.lesson-del__btn-wrap {
    min-height: 26px;
}

.one-time__delete-btn {
    display: block;
    height: 25px;
}
.onetime__creation-wrap{
    background: rgb(255, 255, 255, 0.6);
    padding: 20px 30px;
    border-radius: 25px;
    border: 2px solid #0093D3;
    margin-right: 15px;
}

.onetime__creation-wrap input,
.onetime__creation-wrap select,
.onetime__creation-wrap button{
    width: 120px;   
    border: none;
    background-color: rgb(255, 255, 255);
    margin-bottom: 10px;
    border-radius: 8px;
    height: 30px;
    width: 150px;
    padding-left: 10px;
    color: #0264AF;
    font-size: 16px;
    font-family: "Montserrat", "Arial", sans-serif;
    box-sizing: border-box;
} 

button.yc-button {
    display: block;
    color: #fff;
    background-color: #0260AC;
    padding: 5px 15px;
    box-sizing: border-box;
    border: 0;
    border-radius: 10px;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 20px;
}

.techer__changer-line {
    display: flex;
    align-items: center;
}

.techer__changer-line .teachers-select {
    width: 210px;
    border: none;
    height: 25px;
}

.group__teacher {
    display: flex;
    align-items: center;
    margin-top: 10px;
    min-height: 10px;
    box-sizing: border-box;
}

.teacher-change__btn {
    border: 1px solid #ebebeb;
    border-radius: 10px;
    width: 30px;
    height: 25px;

    background-color: #1d9ef5;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.teacher-changer__wrap {
    position: relative;
    z-index: 5;
    height: 24px;
    box-sizing: border-box;
}

.teacher-change__btn:hover {
    opacity: .8;
}

.group-teacher__name {
    margin: 0;
    margin-right: 10px;
    cursor: pointer;

}

.group-teacher__name:hover {
    background: rgb(232, 232, 232);
}

.teacher-change__btn img {
    width: 60%;
}

.teacher-change__btn--delete {
    background-color: rgb(235, 32, 32);
    color: #fff;
}

.group__mgt {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

}

.group__mgt .hour-info__lessons-length {
    margin-bottom: 0px;
}

.group__mgt .hour-info__lessons-length input{
    width: 50px;
}

.group-name__changer {
    height: 34px;
    margin-bottom: 13px;
    font-size: 28px;
    width: 200px;
    border: none;
    border-radius: 5px;
}

.group-hours {
    position: relative;
    cursor: pointer;
    padding: 2px;
    min-width: 22px;
    height: 22px;
    text-align: center;
    margin-right: 5px;
    border-radius: 50px;
    border: 2px solid #0093D3;
}

.group-hours:hover {
    background-color: #00A7E2;
}

.hours--for-teacher {
    display: flex;
}

.group-hours__change-wrap {
    position: absolute;
    top: -15px;
    left: 20px;
    background-color: #fff;
    width: 140px;
    padding: 5px 15px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 10;
}

.group-block {
    display: flex;
  }



  .group__amount-hours {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    background-color: #0093D3;
    border-radius: 50px;
    padding: 2px 3px;
    color: #fff;
    font-weight: bold;
  }
  

.group-hours__change-wrap input[type=number] {
    width: 55px;
}

.group-hours__manual {
    font-size: 10px;
}

.group-hours__close-btn {
    color: red;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}

.lessons-settings__wrap {
    display: flex;
}

.group-hours__course-name {
    width: 100px;
}

.group-manual__wrap {
    display: flex;
    margin-bottom: 10px;
}

.group-manual__wrap button {
    font-size: 12px;
}

.data-info__row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.data-info__row  .lesson-item__date {
    width: 90px;
    text-align: left;
}

.data-info__row  .lesson-attandance__box {
    margin-bottom: 0;
    margin-left: 5px;
}

.info-header__date {
    width: 90px;
    align-self: end;
    padding-bottom: 40px;
}

.group-info__header {
    display: flex;
    margin-top: 20px;
    margin-bottom: 30px;
}

.group-info__header .student-list {
    padding: 0;
    display: flex;
    justify-content: space-between;
}

.group-info__header .student-list__item {
    width: 145px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.attandance-wrap {
    margin-bottom: 18px;
}

.attandance-select__without-pay {
    height: 38px;
    width: 50px;
    margin-bottom: 2px;
}

.student-list__name--debet {
    background: linear-gradient(116.26deg, #F2164B 33.48%, #DA7E70 96.9%);
    color: #000;
    border-radius: 5px;
}

.data-info__row .attandance-wrap {
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
}

.group__attandance-wrap {
    margin-bottom: 18px;
    display: flex;

}

.student-progress__name {
    font-size: 12px;
    margin-top: -7px;
    margin-left: 5px;
}

.student-progress__name-wrap {
    width: 80px;
}

.attendance__price {
    margin-top: 4px;
    cursor: pointer;
    font-size: 12px;
    border: 1px solid #0260AC;
    padding: 2px 5px;
    border-radius: 5px;
    height: fit-content;
    margin-left: 4px;
}

.attendance__price:hover {
    background-color: #00A7E2;
    color: #fff;
}

.attendance__price:active {
    background-color: #0260AC;
    color: #fff;
}

.attandance-select__wrap {
    display: flex;
    align-items: center;
}

.data-info__row .student-progress__name {
    margin-top: 2px;
}

.course-of-lesson__wrap {
    margin-left: 10px;
    margin-right: 10px;
}

.group-info__progress-name {
    display: inline-block;
    width: 100px;
}

.data-info__row  .lessons__manager-check-wrap {
    margin-left: 10px;
}

.lesson-item__date--creation {
    color: #739dbe;
}

.group-info__progress-name {
    width: 130px;
}

.group-info__progress-list {
    margin-top: 20px;
}

.group-info__progress-list li{
    display: flex;
    margin-bottom: 10px;
}

.hour-info__manual {
    cursor: pointer;
    border: 2px solid #0260AC;
    margin-left: 5px;
    border-radius: 10px;
}

.hour-info__manual form {
    display: flex;
    align-items: center;
    padding: 1px 3px;
}

.hour-info__manual b{
    padding: 0 10px;
}

.hour-info__manual input[type=text] {
    width: 50px;
    border: 0;
}

.hour-info__manual button {
    display: block;
    height: 100%;
    cursor: pointer;
}

.hour-info__manual b:hover {
    opacity: .6;
}

.hour-info__link {
    display: inline-block;
    padding: 3px 10px;
    border: 1px solid #bbb;
    border-radius: 50%;
    margin-right: 5px;
    margin-left: 10px;
}

.hour-info__link:hover {
    background-color: #bbb;
    color: #fff;
}

.data-info__teacher-name {
    margin-right: 10px;
}

.data-info__row .lesson-del__btn-wrap {
    min-height: initial;
}

.group-info__progress-del-btn {
    margin-left: 15px;
}

.group-info__hour-sum {
    display: block;
    padding: 2px 8px;
    margin: 0 5px;
    
    background-color: #1d9ef5;
    color: #fff;

    border-radius: 50%;
}

.hour-info__main {
    display: flex;
    /* flex-direction: column-reverse; */
}

.hour-info__main .group__name {
    width: fit-content;
}

.hour-info__lessons-length {
    margin-bottom: 10px;
}

.hour-info__lessons-length input {
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 14px;
    border: 2px solid #0260AC;
    width: 100px;
}

.notes-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.notes {
    padding-top: 5px;
    margin-bottom: 10px;
    /* padding-left: 50px; */
}

.notes button {
    cursor: pointer;
}

.group-name__wrap {
    height: 29px;
}

.group-info__date-column {
    position: relative;
}


.group-info__date-column .lesson-creation__wrap {
    flex-direction: row;
    align-items: center;
    
    width: fit-content;
    
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
   
    border-radius: 10px;
    background-color: #ebebeb;
    border: 2px solid #0260AC;
    
    position: absolute;
    top: -5px;
}

.group-info__date-column .lesson-create__btn {
    margin-top: 0px;
}

.student__balance {
    margin-left: 5px;
    opacity: .6;
    color: #0260AC;
    font-family: "Play", Arial, 'sans-serif';

    font-weight: bold;
}

.student__balance--positive {
    color: #5DBC26;
}

.student__balance--negative {
    color: #F2164B;
}

.group-elem__schedule-list {
    display: flex;
    margin-left: 10px;
    flex-wrap: wrap;
    align-items: center;
}

.group-elem__schedule-list .schedule-item__time {
    margin-right: 4px;
}

.group-elem__schedule-list li {
    margin-right: 10px;
}

.group-elem__schedule-del-btn {
    border: none;
    background: transparent;
    color: rgba(233, 69, 69, 1);
    padding: 0px 4px;
    cursor: pointer;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    box-sizing: border-box;
}

.group-elem__schedule-item {
    display: flex;
    align-items: center;
}

.group-elem__schedule-del-btn:hover {
    background-color: rgba(233, 69, 69, 1);
    color: #fff;
}

.group__schedule-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
}

.group__schedule-wrap .schedule__creating-form {
    border: none;
    padding-top: 0;
}

.group__schedule-wrap .schedule__creating-form input {
    padding: 0 5px;
}

.group__schedule-wrap .schedule__item {
    margin-bottom: 0;
}

.group-elem__open-btn {
    background: var(--blue-gradient);
    border: none;
    border-radius: 5px;
    color: #fff;
    padding: 0px 10px;
    min-height: 20px;
    cursor: pointer;
}

.group-elem__open-btn:hover {
    opacity: .6;
}

.group-elem__schedule-content {
    cursor: pointer;
}

.group-elem__schedule-content:hover {
    opacity: .6;
}

.lesson-description {
    position: relative;
}

.lesson-description__text {
    position: absolute;
    border: 1px solid #0260AC;
    border-radius: 10px;
    top: -114px;
    background-color: #fff;
    padding: 5px 10px;
    text-align: left;
    font-size: 12px;
    line-height: 10px;
    height: 100px;
    width: 200px;
    z-index: 9999;
}

.lesson-description__open-btn {
    border: none;
    border-radius: 6px;
    background-color: var(--dark-blue);
    color: #fff;
    cursor: pointer;
}

.lesson-description__open-btn:hover {
    opacity: .8;
}

.lesson-description__open-btn:active {
    opacity: .6;
}

.lesson-description__data {
    cursor: pointer;
}

.lesson-description__data:hover {
    opacity: .8;
}

.lesson-description__data:active {
    opacity: .6;
}

.lesson-description__text textarea {
    padding: 5px 5px;
    border-radius: 8px;
    border: 1px solid var(--dark-blue);
    margin-bottom: 2px;
}
.lesson-description__text button {
    border-radius: 8px;
    border: none;
    color: #fff;
    padding: 2px 10px;
    background-color: var(--dark-blue);
    cursor: pointer;
}

.lesson-description__text button:hover {
    opacity: .8;
}

.lesson-description__text button:active {
    opacity: .6;
}

@media screen and (max-width: 420px) {
    .group-wrap {
        overflow-x: scroll;
        width: initial;
    }

    .student-list {
        margin-right: 10px;
    }

    .student-list li {
        font-size: 16px;
    }

    .group-block {
        flex-direction: row;
        width: 300px;
    }

    .student-list {
        padding-left: 0;
    }

    .lesson__hours {
        margin-left: 0;
    }

    .teacher-info {
        margin-bottom: 15px;
    }

    .nav-item--lesson {
        width: 200px;
    }

    .nav-link--lesson {
        border: 1px solid #0260AC;
        width: 100%;
        display: block;
        text-align: center;
        padding: 5px;
    }

    .teacher-info {
        width: 260px;
        justify-content: space-between;
    }

    .teacher-info__item {
        margin-right: 0;
    }

    .group__name {
        font-size: 18px;
    }

    .onetime__wrap {
        flex-wrap: wrap;
    }

    .group__amount-hours {
        left: 5px;
        top: 10px;
        right: initial;
    }

    .lessons-item__archive-btn {
        margin-left: 15px;
    }
}